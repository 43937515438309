import { render, staticRenderFns } from "./BaseInfoFormBlock.vue?vue&type=template&id=4d37c874&scoped=true"
import script from "./BaseInfoFormBlock.vue?vue&type=script&lang=js"
export * from "./BaseInfoFormBlock.vue?vue&type=script&lang=js"
import style0 from "./BaseInfoFormBlock.vue?vue&type=style&index=0&id=4d37c874&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d37c874",
  null
  
)

export default component.exports